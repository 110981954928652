import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { IoIosArrowDown, IoIosArrowUp, IoIosMail } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { SiPivotaltracker } from "react-icons/si";
import { MdDashboard, MdContacts, MdLogout } from "react-icons/md";
import { MdAddBox } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";

import Q from "../../Assets/Qtrackr.png";
import loram from "../../Assets/loram.png";
import checkSessionValidity from "../../CheckSessionValidity";

const AddLead2 = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const token = localStorage.getItem('token');
    if (!token){
        enqueueSnackbar('Login to Navigate!',{variant:'error'});
        navigate('/');
    }

    const [leadTracker, setLeadTracker] = useState(true);
    const [Contact, setshowcontact] = useState(false);
    const [showLogout, setshowlogout] = useState(false);

    const toggleLeadTracker = (e) => {
        e.preventDefault();
        setLeadTracker(!leadTracker);
        setshowcontact(false);
    };

    const handleVisibilityChange = useCallback(() => {
        if (!document.hidden) { // Fires only when the tab becomes visible
            const isSessionValid = checkSessionValidity();
            if (!isSessionValid) {
                navigate('/login'); // Redirect to login if session is invalid
            }
        }
    }, [navigate]);

    useEffect(() => {
        // Check session validity on component mount
        const isSessionValid = checkSessionValidity();
        if (!isSessionValid) {
            navigate('/login');
        }

        // Add event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup listener on unmount
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleVisibilityChange,navigate]);



    const toggleContact = (e) => {
        e.preventDefault();
        setshowcontact(!Contact);
        setLeadTracker(false);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        window.location.href = 'mailto:info@dext.site?subject=Contact%20Us&body=Hello%2C%0D%0A%0D%0A';
    };

    const addLead = (e) => {
        e.preventDefault();
        navigate('/addlead_3');
    };
    const dash = (e) => {
        e.preventDefault();
        navigate('/dashboard_3');
    };
    const leadReport = (e) => {
        e.preventDefault();
        navigate('/leadreport_3');
    };

    const logout = (e) => {
        e.preventDefault();
        setshowlogout(!showLogout);
    };
    const logoutUser = (e) => {
        e.preventDefault();
        enqueueSnackbar('Successfully Logged out!', { variant: 'success'});
        localStorage.removeItem('token');
        navigate('/');
    };


    const [ClientName, setClientName] = useState('');
    const [TypeService, setTypeService] = useState('');
    const [LeadType, setLeadType] = useState('');
    const [AssignedGroup, setAssignedGroup] = useState('');
    const [QuotedValue, setQuotedValue] = useState('');
    const [ProjectName, setProjectName] = useState('');
    const [LeadDate, setLeadDate] = useState('');
    const [Reference, setReference] = useState('');
    const [ClientPhone, setClientPhone] = useState('');
    const [ClientContactName, setClientContactName] = useState('');
    const [ClientEmail, setClientEmail] = useState('');
    const [Status, setStatus] = useState('');
    const [Source, setSource] = useState('');
    const [FollowupDate, setFollowupDate] = useState('');
    const [Probability, setProbability] = useState('');
    const [showProb, setShowProb] = useState(false);
    const [showRef, setshowRef] = useState(false);


    const Submit = async(e) => {
        e.preventDefault();
        if(!ClientName || !LeadType || !FollowupDate || !LeadDate || !Source || !ClientPhone || !ClientContactName || !TypeService || !Status || !ProjectName || !QuotedValue){
          enqueueSnackbar('Please fill all the fields!', { variant: 'error'});
        }
        else{
            try {
                const response = await axios.post('https://qtracker.site:9001/api/leads', {
                  ClientName: ClientName,
                  ClientEmail: ClientEmail,
                  ProjectName: ProjectName,
                  LeadDate: LeadDate,
                  ClientPhone: ClientPhone,
                  QuotedValue: QuotedValue,
                  ClientContactName: ClientContactName,
                  Status: Status,
                  Source: Source,
                  TypeService: TypeService,
                  AssignedGroup: AssignedGroup,
                  LeadType: LeadType,
                  FollowupDate: FollowupDate,
                  Probability: Probability,
                  Reference: Reference
                });
          
                if (response.status >= 200 && response.status < 300) {
                  enqueueSnackbar('Lead Added Successfully!', { variant: 'success'});
                  setClientName('');
                  setLeadDate('');
                  setClientPhone('');
                  setClientContactName('');
                  setStatus('');
                  setClientEmail('');
                  setProjectName('');
                  setQuotedValue('');
                  setTypeService('');
                  setAssignedGroup('');
                  setLeadType('');
                  setFollowupDate('');
                  setShowProb(false);
                  setProbability('');
                  setSource('');
                  setshowRef(false);
                  setReference('');
                } else {
                  enqueueSnackbar('Please Check Your Connection!', { variant: 'error'});
                }
              } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!',{variant:'error'});
              }
        }
    };

    const Cancel = (e) => {
        e.preventDefault();
        setClientName('');
        setClientEmail('');
        setLeadDate('');
        setClientPhone('');
        setClientContactName('');
        setStatus('');
        setProjectName('');
        setQuotedValue('');
        setTypeService('');
        setLeadType('');
        setAssignedGroup('');
        setSource('');
        setFollowupDate('');
        setShowProb(false);
        setProbability('');
        setshowRef(false);
        setReference('');
    };



    const Prob = (e) => {
        e.preventDefault();
        if (Status === 'Pending'){
            setShowProb(true);
        }
        else{
            setShowProb(false);
        }
    };

    const Ref = (e) => {
        e.preventDefault();
        if (Source === 'Reference'){
            setshowRef(true);
        }
        else{
            setshowRef(false);
        }
    };


    const [GroupNames, setGroupNames] = useState([]);
    useEffect(() => {
        const fetchGroupName = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/groups');
                if (response.status === 200) {
                    setGroupNames(response.data);
                } else {
                    enqueueSnackbar('Failed to fetch GroupNames', { variant: 'error' });
                }
            } catch (error) {
                console.error('Error fetching GroupNames:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchGroupName();
    }, [enqueueSnackbar]);


    const [serviceTypes, setServiceTypes] = useState([]); 
    useEffect(() => {
        const fetchServiceTypes = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/serviceTypes');
                if (response.status >= 200 && response.status < 300) {
                    setServiceTypes(response.data);
                } else {
                    console.log('Failed to fetch Data');
                }
            } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchServiceTypes();
    }, [enqueueSnackbar]);


    const [Sources, setSources] = useState([]);
    useEffect(() => {
        const fetchSources = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/Source');
                if (response.status === 200) {
                    setSources(response.data);
                } else {
                    enqueueSnackbar('Failed to fetch sources', { variant: 'error' });
                }
            } catch (error) {
                console.error('Error fetching sources:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchSources();
    }, [enqueueSnackbar]);


    const [LeadTypes, setLeadTypes] = useState([]); 
    useEffect(() => {
        const fetchLeadTypes = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/LeadTypes');
                if (response.status >= 200 && response.status < 300) {
                    setLeadTypes(response.data);
                } else {
                    console.log('Failed to fetch Data');
                }
            } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchLeadTypes();
    }, [enqueueSnackbar]);

    const [statuses, setStatuses] = useState([]); 
    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/Status');
                if (response.status >= 200 && response.status < 300) {
                    setStatuses(response.data);
                } else {
                    enqueueSnackbar('Failed to fetch Status', { variant: 'error' });
                }
            } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchStatus();
    }, [enqueueSnackbar]);

    function formatNumberWithCommas(number) {
        if (!number) return ''; // Handle case where number is undefined or null
        const parts = number.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join('.');
    }

    return (
        <>
            <div className='fixed flex justify-end z-10 shadow-2xl overflow-hidden py-3 w-full text-white bg-black'>
                <div className='cursor-pointer mr-4'>
                    <FaUserCircle onClick={logout} size={28}/>
                </div>
                {showLogout && (
                <>
                    <div className='fixed text-black text-center px-2 w-1/12 shadow-2xl overflow-hidden bg-white border border-gray-400 mr-1 mt-10 z-50 justify-end rounded-lg'>
                        <h1 className='px-6 py-2 rounded-md my-1'>Name</h1>
                        <h1 onClick={logoutUser} className='flex px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white'><MdLogout size={24}/> Logout</h1>
                    </div>
                </>
                )}
            </div>

            <div className='fixed z-20 flex flex-col shadow-2xl overflow-hidden min-h-screen w-1/6 bg-black text-white' style={{width:"310px"}}>
                <img className='p-4' src={Q} alt="Q-Trackr Logo" />
                <h1 onClick={toggleLeadTracker} className='flex grid-cols-2 p-2 cursor-pointer bg-white mx-3 rounded-md my-3 text-black'><SiPivotaltracker size={24}/>&nbsp;Lead Tracker {leadTracker ? <IoIosArrowUp style={{ alignItems: 'center', marginLeft: '60px' }} size={24} /> : <IoIosArrowDown style={{ alignItems: 'center', marginLeft: '60px' }} size={24} />}</h1>
                {leadTracker && (
                    <div className='ml-3'>
                        <h1 onClick={dash} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><MdDashboard style={{ alignItems: 'center' }} size={24} />&nbsp;Dashboard</h1>
                        <h1 onClick={addLead} className='p-1 cursor-pointer bg-white mx-3 flex rounded-md mb-1 text-black'><MdAddBox style={{ alignItems: 'center' }} size={24} />&nbsp;Add Lead</h1>
                        <h1 onClick={leadReport} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><BiSolidReport style={{ alignItems: 'center' }} size={24} />&nbsp;Lead Reports</h1>

                    </div>
                )}
                <h1 onClick={toggleContact} className='flex p-2 cursor-pointer hover:bg-white mx-3 rounded-md my-2 hover:text-black'><MdContacts size={24}/>&nbsp; Contact Us&nbsp;&nbsp;{Contact ? <IoIosArrowUp style={{ alignItems: 'center', marginLeft: '60px' }} size={24} /> : <IoIosArrowDown style={{ alignItems: 'center', marginLeft: '60px' }} size={24} />}</h1>
                { Contact && (
                    <>
                        <div className='ml-3'>
                            <h1 onClick={sendEmail} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md hover:text-black'><IoIosMail style={{ alignItems: 'center' }} size={24} />&nbsp;info@dext.site</h1>
                        </div>
                    </>
                )}
                <img className='p-4 mt-auto bg-white' src={loram} alt="Loram Logo" />
            </div>

            <div className='ml-80 p-4 bg-white  border border-gray-200 relative overflow-hidden shadow-lg w-3/4 justify-center top-24'>
                <h1 className='text-2xl mb-2 font-semibold'>Add Lead</h1><hr /><hr /><br />
                <div className='flex justify-start w-full'>
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="Name">Client Name:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Name' value={ClientName} onChange={(e) => setClientName(e.target.value)}/>
                    </div>
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="date">Lead Date:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="date" id="date" name="date" value={LeadDate} onChange={(e) => setLeadDate(e.target.value)}/>
                    </div>
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="Name">Source:</label>
                        <select  onClick={Ref} className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={Source} onChange={(e) => setSource(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Select Source</option>
                            {Sources.map(Sourc => (
                                <option key={Sourc.id} value={Sourc.newSource}>{Sourc.newSource}</option>
                            ))}
                        </select>
                    </div>
                    {showRef && (
                        <div className='mx-3 w-full'>
                            <label className='block' htmlFor="Name">Reference:</label>
                            <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Reference' value={Reference} onChange={(e) => setReference(e.target.value)}/>
                        </div>
                    )}
                </div>
                <br />
                <div className='flex justify-start w-full'>
                    <div className='mx-4 w-1/3'>
                        <label className='block' htmlFor="Name">Client Contact Person Name:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Name' value={ClientContactName} onChange={(e) => setClientContactName(e.target.value)}/>
                    </div>
                    <div className='mx-4 w-1/3'>
                        <label className='block' htmlFor="Name">Mobile Number:</label>
                        <PhoneInput country={'us'} value={ClientPhone} onChange={(value) => setClientPhone(value)} inputProps={{  required: true, className: 'w-11/12 border border-gray-400 outline-0 rounded overflow-hidden shadow-md ml-9 p-2', style: { fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif" }}}/>
                    </div>
                    <div className='mx-4 w-1/3'>
                        <label className='block' htmlFor="email">Email ID:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Email ID' value={ClientEmail} onChange={(e) => setClientEmail(e.target.value)}/>
                    </div>
                </div>
                <br />
                <div className='flex justify-start w-full'>
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="Name">Project Name:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Project Name' value={ProjectName} onChange={(e) => setProjectName(e.target.value)}/>
                    </div>
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="Name">Type of Service:</label>
                        <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={TypeService} onChange={(e) => setTypeService(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Select Service Type</option>
                            {serviceTypes.map(serviceType => (
                                <option key={serviceType.id} value={serviceType.ServiceType}>{serviceType.ServiceType}</option>
                            ))}
                        </select>
                    </div>
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="Name">Quoted Value:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Quoted Value' inputMode="numeric" pattern="[0-9]*" value={formatNumberWithCommas(QuotedValue)} onChange={(e) => setQuotedValue(e.target.value.replace(/,/g, '').replace(/\D/g, ''))}/>
                    </div>
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="auth">Assign Group(s):</label>
                        <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={AssignedGroup} onChange={(e) => setAssignedGroup(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Assign Group(s)</option>
                            {GroupNames.map(group => (
                                <option key={group.id} value={group.newGroupName}>{group.newGroupName}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <br />
                <div className='flex justify-start w-full'>
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="Name">Lead Type:</label>
                        <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={LeadType} onChange={(e) => setLeadType(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Select Lead type</option>
                            {LeadTypes.map(LeadTyp => (
                                <option key={LeadTyp.id} value={LeadTyp.LeadType}>{LeadTyp.LeadType}</option>
                            ))}
                        </select>
                    </div>
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="Name">Status:</label>
                        <select onClick={Prob} className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={Status} onChange={(e) => setStatus(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Select Current Status</option>
                            {statuses.map(statuse => (
                                <option key={statuse.id} value={statuse.status}>{statuse.status}</option>
                            ))}
                        </select>
                    </div>
                    {showProb && (
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="Name">Probability:</label>
                        <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={Probability} onChange={(e) => setProbability(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Select Probability</option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                        </select>
                    </div>
                    )}
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="date">Next Follow-up Date:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="date" id="date" name="date" value={FollowupDate} onChange={(e) => setFollowupDate(e.target.value)}/>
                    </div>
                </div>
                <br />
                <div className='flex mt-14 justify-center'>
                    <button onClick={Submit} className='mx-2 py-2 px-5 bg-blue-600 hover:bg-blue-900 overflow-hidden shadow-md text-white rounded-sm'>Submit</button>
                    <button onClick={Cancel} className='mx-2 py-2 px-5 bg-black hover:bg-white hover:text-black border border-black overflow-hidden shadow-md text-white rounded-sm'>Cancel</button>
                </div>
                <br />
            </div>
            <br /><br /><br /><br /><br /><br />
        </>
    );
};

export default AddLead2;

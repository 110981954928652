import React, { useState,useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import Chart from 'chart.js/auto';

import { IoIosArrowDown, IoIosArrowUp, IoIosMail } from "react-icons/io";
import { FaUserCircle, FaTrash } from "react-icons/fa";
import { SiPivotaltracker } from "react-icons/si";
import { MdDashboard, MdContacts, MdLogout, MdSearch, MdEditSquare } from "react-icons/md";
import { MdAddBox } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import checkSessionValidity from '../CheckSessionValidity';

import loram from "../Assets/loram.png";
import Q from "../Assets/Qtrackr.png";
import icon from "../Assets/icon1.png";
import DatePicker from 'react-datepicker';
Chart.defaults.plugins.legend.display = false;
const Dashboard1 = () => {

    
const [probabilityFilterDateFrom, setProbabilityFilterDateFrom] = useState();
const [probabilityFilterDateTo, setProbabilityFilterDateTo] = useState();
const [statusFilterDateFrom, setStatusFilterDateFrom] = useState();
const [statusFilterDateTo, setStatusFilterDateTo] = useState();
const [LeadCount, setLeadCount] = useState(0);
const [totalQuotedValue, setTotalQuotedValue] = useState(0);
const [wonLeadCount, setWonLeadCount] = useState(0);
const [wonTotalQuotedValue, setWonTotalQuotedValue] = useState(0);
const [wonRecurringLeadCount, setWonRecurringLeadCount] = useState(0);
const [wonRecurringTotalQuotedValue, setWonRecurringTotalQuotedValue] = useState(0);
const [pendingLeadCount, setPendingLeadCount] = useState(0);
const [pendingTotalQuotedValue, setPendingTotalQuotedValue] = useState(0);
const [lostLeadCount, setLostLeadCount] = useState(0);
const [lostTotalQuotedValue, setLostTotalQuotedValue] = useState(0);
const [filteredLeadReports, setFilteredLeadReports] = useState([]);
const [groupFilter, setGroupFilter] = useState('');
const [statusFilter, setStatusFilter] = useState('');
const [, setShowProb] = useState(false);
const [sourceFilterDateFrom, setSourceFilterDateFrom] = useState();
const [sourceFilterDateTo, setSourceFilterDateTo] = useState();
const [selectedSource, setSelectedSource] = useState();
const [potentialStatus, setPotentialStatus] = useState([]);
const [potentialSource, setPotentialSource] = useState([]);
const [probabilityStatus, setProbabilityStatus] = useState([]);
const [totalStatusAmount, setTotalStatusAmount] = useState(0);
const [totalProbabilityAmount, setTotalProbabilityAmount] = useState(0);
const [totalSourceAmount, setTotalSourceAmount] = useState(0);
const [, setTotalStatusCount] = useState(0);
const [, setTotalProbabilityCount] = useState(0);
const [, setTotalSourceCount] = useState(0);
const statusChartRef = useRef(null);
const sourceChartRef = useRef(null);
const probChartRef = useRef(null);

/*const getRowClass = (followUpDate) => {
    if (!followUpDate) return ""; // No color if date is not present
    const today = new Date();
    const followDate = new Date(followUpDate);
    const diffInMonths = (followDate.getFullYear() - today.getFullYear()) * 12 + (followDate.getMonth() - today.getMonth());

    if (diffInMonths < 2) return "bg-red-700"; // Less than 2 months: Red
    if (diffInMonths >= 2 && diffInMonths < 4) return "bg-orange-500"; // 2-4 months: Orange
    if (diffInMonths >= 4 && diffInMonths < 6) return "bg-green-500"; // 4-6 months: Green

    return "bg-green-500"; // Default: no color if more than 6 months
};*/
const getRowClass2 = (Status) => {
    if (!Status) return ""; // No color if date is not present
    

    if (Status === "Won") return "bg-green-700"; // Less than 2 months: Red
    if (Status === "Won Recurring") return "bg-blue-700";
    if (Status === "Pending") return "bg-yellow-400";
    if (Status === "Lost") return "bg-red-700";
   

   
};

useEffect(() => {
    // Update the count of filtered leads
    setLeadCount(filteredLeadReports.length);

    // Calculate the total QuotedValue
    const total = filteredLeadReports.reduce((sum, lead) => sum + (lead.QuotedValue || 0), 0);
    setTotalQuotedValue(total);

    // Filter and calculate count and total for leads with "Won" status
    const wonLeads = filteredLeadReports.filter(lead => lead.Status === "Won");
    setWonLeadCount(wonLeads.length);

    const wonTotal = wonLeads.reduce((sum, lead) => sum + (lead.QuotedValue || 0), 0);
    setWonTotalQuotedValue(wonTotal);

    const wonRecurringLeads = filteredLeadReports.filter(lead => lead.Status === "Won Recurring");
    setWonRecurringLeadCount(wonRecurringLeads.length);

    const wonRecurringTotal = wonRecurringLeads.reduce((sum, lead) => sum + (lead.QuotedValue || 0), 0);
    setWonRecurringTotalQuotedValue(wonRecurringTotal);

    const pendingLeads = filteredLeadReports.filter(lead => lead.Status === "Pending");
    setPendingLeadCount(pendingLeads.length);

    const pendingTotal = pendingLeads.reduce((sum, lead) => sum + (lead.QuotedValue || 0), 0);
    setPendingTotalQuotedValue(pendingTotal);

    const lostLeads = filteredLeadReports.filter(lead => lead.Status === "Lost");
    setLostLeadCount(lostLeads.length);

    const lostTotal = lostLeads.reduce((sum, lead) => sum + (lead.QuotedValue || 0), 0);
    setLostTotalQuotedValue(lostTotal);

}, [filteredLeadReports]);

const handleSubmit = () => {

    console.log(groupFilter);  // Check the value of groupFilter
    console.log(LeadReports);
    if (groupFilter === "") {
        // If 'All' is selected, reset the filtered data to the original data
        setFilteredLeadReports(LeadReports);

    } else {
        // Filter the LeadReports based on the selected group
        const filtered = LeadReports.filter(report => report.AssignedGroup === groupFilter);
        setFilteredLeadReports(filtered);
        console.log(filteredLeadReports);
    }

};

const handleFilter = () => {
    // Filter data based on date range and group selection
    const filteredData = filteredLeadReports.filter((LeadReport) => {
        const reportDate = new Date(LeadReport.LeadDate); // Replace 'date' with the actual date field name
        const isWithinDateRange =
            (!probabilityFilterDateFrom || reportDate >= probabilityFilterDateFrom) &&
            (!probabilityFilterDateTo || reportDate <= probabilityFilterDateTo);
        const isWithinGroup =
            !AssignedGroup || AssignedGroup === "all" || LeadReport.AssignedGroup === AssignedGroup;

        return isWithinDateRange && isWithinGroup;
    });

    // Pass the filtered data to aggregate function
    aggregateProbabilityData(filteredData);
};

const aggregateProbabilityData = (data) => {
    const probMap = new Map();
    let totalProbAmount = 0;
    let totalCount = 0;

    data.forEach((LeadReport) => {
        const prob = LeadReport.Probability;
        if (!prob) return;

        const count = probMap.has(prob) ? probMap.get(prob).count + 1 : 1;
        const amount = parseFloat(LeadReport.QuotedValue);
        totalProbAmount += amount;
        totalCount++;
        probMap.set(prob, { count, amount: (probMap.get(prob)?.amount || 0) + amount });
    });

    const potentialProbabilityArray = Array.from(probMap, ([prob, { count, amount }]) => ({
        prob,
        count,
        amount,
        percentage: ((amount / totalProbAmount) * 100).toFixed(2),
    }));

    setProbabilityStatus(potentialProbabilityArray);
    setTotalProbabilityAmount(totalProbAmount);
    setTotalProbabilityCount(totalCount);
};
const Prob = (e) => {
    e.preventDefault();
    if (Status === 'Pending') {
        setShowProb(true);
    }
    else {
        setShowProb(false);
    }
};
const handlePotentialStatusFilter = () => {
    // Filter data based on date range and status selection
    const filteredData = filteredLeadReports.filter((item) => {


        const itemDate = new Date(item.LeadDate); // Replace 'date' with your actual date field name

        const isWithinDateRange =
            (!statusFilterDateFrom || itemDate >= statusFilterDateFrom) &&
            (!statusFilterDateTo || itemDate <= statusFilterDateTo);



        const isMatchingStatus =
            !statusFilter || statusFilter === "all" || item.Status === statusFilter;



        return isWithinDateRange && isMatchingStatus;
    });

    // Update state or pass filtered data for further processing
    setPotentialStatus(filteredData);
    aggregatePotentialStatusData(filteredData);
    console.log(potentialStatus);


};
const aggregatePotentialStatusData = (filteredData) => {

    const statusMap = new Map();
    let totalStatusAmount = 0;
    let totalCount = 0;

    filteredData.forEach((LeadReport) => {
        const status = LeadReport.Status;
        if (!status) return;

        const count = statusMap.has(status) ? statusMap.get(status).count + 1 : 1;
        const amount = parseFloat(LeadReport.QuotedValue);
        totalStatusAmount += amount;
        totalCount++;
        statusMap.set(status, { count, amount: (statusMap.get(status)?.amount || 0) + amount });
    });

    const potentialStatusArray = Array.from(statusMap, ([status, { count, amount }]) => ({
        status,
        count,
        amount,
        percentage: ((amount / totalStatusAmount) * 100).toFixed(2),
    }));

    setPotentialStatus(potentialStatusArray);
    console.log(potentialStatusArray);
    setTotalStatusAmount(totalStatusAmount);
    setTotalStatusCount(totalCount);

};
const handleLeadingSourceFilter = () => {
    // Filter data based on date range and status selection
    const filteredData = filteredLeadReports.filter((item) => {


        const itemDate = new Date(item.LeadDate); // Replace 'date' with your actual date field name

        const isWithinDateRange =
            (!sourceFilterDateFrom || itemDate >= sourceFilterDateFrom) &&
            (!sourceFilterDateTo || itemDate <= sourceFilterDateTo);

        console.log("selected source", selectedSource)
        const isMatchingSource =
            !selectedSource || selectedSource === "all" || item.Source.trim().toLowerCase() === selectedSource.trim().toLowerCase();


        console.log("Item:", item);
        console.log("isWithinDateRange:", isWithinDateRange);
        console.log("isMatchingSource:", isMatchingSource);

        return isWithinDateRange && isMatchingSource;
    });

    // Update state or pass filtered data for further processing
    setPotentialSource(filteredData);
    console.log("filtered data", filteredData);
    aggregatePotentialSourceData(filteredData);
    console.log(potentialStatus);


};


useEffect(() => {
    console.log("potential source filter", potentialSource);
}, [potentialSource]);



// Helper function to format the date if not already done
/*const formattheDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString();
};*/

const aggregatePotentialSourceData = (filteredData) => {

    const sourceMap = new Map();
    let totalSourceAmount = 0;
    let totalCount = 0;

    filteredData.forEach((LeadReport) => {
        const source = LeadReport.Source;
        if (!source) return;

        const count = sourceMap.has(source) ? sourceMap.get(source).count + 1 : 1;
        const amount = parseFloat(LeadReport.QuotedValue);
        totalSourceAmount += amount;
        totalCount++;
        sourceMap.set(source, { count, amount: (sourceMap.get(source)?.amount || 0) + amount });
    });

    const potentialSourceArray = Array.from(sourceMap, ([source, { count, amount }]) => ({
        source,
        count,
        amount,
        percentage: ((amount / totalSourceAmount) * 100).toFixed(2),
    }));

    console.log("Source Map:", sourceMap);

    setPotentialSource(potentialSourceArray);
    console.log(potentialSourceArray);
    setTotalSourceAmount(totalSourceAmount);
    setTotalSourceCount(totalCount);

};

const { enqueueSnackbar } = useSnackbar();
const [Sources, setSources] = useState([]);
    useEffect(() => {
        const fetchSources = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/Source');
                if (response.status === 200) {
                    console.log(response.data);
                    setSources(response.data);
                } else {
                    enqueueSnackbar('Failed to fetch sources', { variant: 'error' });
                }
            } catch (error) {
                console.error('Error fetching sources:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchSources();
    }, [enqueueSnackbar]);


    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }


    /*const downloadFile = async (filename) => {
        try {
            const response = await axios.get(`https://qtracker.site:9001/api/download/${filename}`, {
                responseType: 'blob', // Required to handle binary data
            });

            // Create a URL for the downloaded file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // Set the filename
            document.body.appendChild(link);
            link.click(); // Trigger the download
            link.remove(); // Clean up the link
        } catch (error) {
            console.error('Error downloading the file:', error);
            alert('Error downloading the file!');
        }
    };*/

    const [fileUpload, setFileUpload] = useState(0);

    const handleFileUpload = async (event, id) => {
        const file = event.target.files[0];
        console.log("id", id);
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', id);

        try {
            // Use Axios to send the request
            const response = await axios.post('https://qtracker.site:9001/api/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',  // Required for file upload
                },
            });

            console.log('File uploaded successfully:', response.data.updatedLead);
            // Optionally update the local state
            setFileUpload((prev) => prev + 1);
        } catch (error) {
            // Handle the error
            if (error.response) {
                console.error('Error uploading file:', error.response.data.error || error.response.data);
            } else {
                console.error('Error uploading file:', error.message);
            }
        }
    };

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/leads');
                if (response.status >= 200 && response.status < 300) {
                    setLeadReports(response.data);
                    setFilteredLeadReports(response.data);


                } else {
                    enqueueSnackbar('Failed to fetch Status', { variant: 'error' });
                }
            } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchStatus();
    }, [enqueueSnackbar, fileUpload]);


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Set items per page

    // Calculate pagination details
    const totalItems = filteredLeadReports.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    /*const currentItems = filteredLeadReports.slice(startIdx, endIdx);*/

    const handlePageChange = (page) => setCurrentPage(page);

    const handleNext = () => {
        if (currentPage < totalPages) setCurrentPage(prev => prev + 1);
    };

    const handlePrevious = () => {
        if (currentPage > 1) setCurrentPage(prev => prev - 1);
    };


    const navigate = useNavigate();
    

    const token = localStorage.getItem('token');
    if (!token){
        enqueueSnackbar('Login to Navigate!',{variant:'error'});
        navigate('/');
    }


    const handleVisibilityChange = useCallback(() => {
        if (!document.hidden) { // Fires only when the tab becomes visible
            const isSessionValid = checkSessionValidity();
            if (!isSessionValid) {
                navigate('/login'); // Redirect to login if session is invalid
            }
        }
    }, [navigate]);

    useEffect(() => {
        // Check session validity on component mount
        const isSessionValid = checkSessionValidity();
        if (!isSessionValid) {
            navigate('/login');
        }

        // Add event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup listener on unmount
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleVisibilityChange,navigate]);



    const [leadTracker, setLeadTracker] = useState(true);
    const [Contact, setshowcontact] = useState(false);
    const [showLogout, setshowlogout] = useState(false);

    const toggleLeadTracker = (e) => {
        e.preventDefault();
        setLeadTracker(!leadTracker);
        setshowcontact(false);
    };
    const toggleContact = (e) => {
        e.preventDefault();
        setshowcontact(!Contact);
        setLeadTracker(false);
    };


    const sendEmail = (e) => {
        e.preventDefault();
        window.location.href = 'mailto:info@dext.site?subject=Contact%20Us&body=Hello%2C%0D%0A%0D%0A';
    };

    const addLead = (e) => {
        e.preventDefault();
        navigate('/addlead_2');
    };
    const dash = (e) => {
        e.preventDefault();
        navigate('/dashboard_2');
    };
    const leadReport = (e) => {
        e.preventDefault();
        navigate('/leadreport_2');
    };


    const logout = (e) => {
        e.preventDefault();
        setshowlogout(!showLogout);
    };
    const logoutUser = (e) => {
        e.preventDefault();
        enqueueSnackbar('Successfully Logged out!', { variant: 'success'});
        localStorage.removeItem('token');
        navigate('/');
    };


    const [LeadReports, setLeadReports] = useState([]); 
    const [searchQuery, setSearchQuery] = useState('');
   // const [filteredLeadReports, setFilteredLeadReports] = useState([]);
    const name = localStorage.getItem('name');

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/leads');
                if (response.status >= 200 && response.status < 300) {
                    setLeadReports(response.data);
                    console.log(response.data);
                } else {
                    enqueueSnackbar('Failed to fetch Status', { variant: 'error' });
                }
            } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchStatus();
    }, [enqueueSnackbar]);

    const filterLeadReports = (query) => {
        const filtered = LeadReports.filter(LeadReport =>
            LeadReport.ClientName.toLowerCase().includes(query.toLowerCase()) ||
            LeadReport.ProjectName.toLowerCase().includes(query.toLowerCase()) ||
            LeadReport.ClientContactName.toLowerCase().includes(query.toLowerCase()) || 
            LeadReport.ClientEmail.toLowerCase().includes(query.toLowerCase()) ||
            LeadReport.Status.toLowerCase().includes(query.toLowerCase()) ||
            LeadReport.AssignedGroup.includes(query)
        );
        setFilteredLeadReports(filtered);
    };

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
        filterLeadReports(e.target.value);
    };

    const [ClientName, setClientName] = useState('');
    const [TypeService, setTypeService] = useState('');
    const [LeadType, setLeadType] = useState('');
    const [AssignedGroup, setAssignedGroup] = useState('');
    const [QuotedValue, setQuotedValue] = useState('');
    const [ProjectName, setProjectName] = useState('');
    const [LeadDate, setLeadDate] = useState('');
    const [ClientPhone, setClientPhone] = useState('');
    const [ClientContactName, setClientContactName] = useState('');
    const [ClientEmail, setClientEmail] = useState('');
    const [Status, setStatus] = useState('');
    const [Source, setSource] = useState('');
    const [FollowupDate, setFollowupDate] = useState('');
    const [showEdit, setShowEdit] = useState(false);
    const [Reference, setReference] = useState('');
    const [Probability, setProbability] = useState('');


    const [editingLeadReport, setEditingLeadReport] = useState(null);
    const editLeadReport = (id) => {
        const ReportToEdit = LeadReports.find(LeadReport => LeadReport.id === id);
        setEditingLeadReport(ReportToEdit);
        setShowEdit(true);
    };
        
    useEffect(() => {
        if (editingLeadReport) {
            setClientName(editingLeadReport.ClientName);
            setLeadDate(editingLeadReport.LeadDate);
            setSource(editingLeadReport.Source);
            setClientContactName(editingLeadReport.ClientContactName);
            setClientPhone(editingLeadReport.ClientPhone);
            setClientEmail(editingLeadReport.ClientEmail);
            setProjectName(editingLeadReport.ProjectName);
            setTypeService(editingLeadReport.TypeService);
            setQuotedValue(editingLeadReport.QuotedValue);
            setAssignedGroup(editingLeadReport.AssignedGroup);
            setLeadType(editingLeadReport.LeadType);
            setStatus(editingLeadReport.Status);
            setFollowupDate(editingLeadReport.FollowupDate);
        }
    }, [editingLeadReport]);


    const Submit = async(e) => {
        e.preventDefault();
        if(!ClientName || !FollowupDate || !LeadDate || !Source || !ClientPhone || !ClientContactName || !TypeService || !Status || !ProjectName || !QuotedValue){
          enqueueSnackbar('Please fill all the fields!', { variant: 'error'});
        }
        else{
            try {
                const response = await axios.put(`https://qtracker.site:9001/api/leads/${editingLeadReport.id}`, {
                    ClientName: ClientName,
                    ClientEmail: ClientEmail,
                    ProjectName: ProjectName,
                    LeadDate: LeadDate,
                    ClientPhone: ClientPhone,
                    QuotedValue: QuotedValue,
                    ClientContactName: ClientContactName,
                    Status: Status,
                    Source: Source,
                    TypeService: TypeService,
                    AssignedGroup: AssignedGroup,
                    LeadType: LeadType,
                    FollowupDate: FollowupDate,
                    Probability: Probability,
                    Reference: Reference
                });
          
                if (response.status >= 200 && response.status < 300) {
                  enqueueSnackbar('Follow-Up Updated Successfully!', { variant: 'success'});
                  setClientName('');
                  setLeadDate('');
                  setClientPhone('');
                  setClientContactName('');
                  setStatus('');
                  setClientEmail('');
                  setProjectName('');
                  setQuotedValue('');
                  setTypeService('');
                  setAssignedGroup('');
                  setLeadType('');
                  setFollowupDate('');
                  setReference('');
                  setProbability('');
                  setSource('');
                  setTimeout(() => {
                    window.location.reload();
                }, 2000);
                } else {
                  enqueueSnackbar('Please Check Your Connection!', { variant: 'error'});
                }
              } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!',{variant:'error'});
              }
        }
    };


    const Cancel = (e) => {
        e.preventDefault();
        setClientName('');
        setClientEmail('');
        setLeadDate('');
        setClientPhone('');
        setClientContactName('');
        setStatus('');
        setProjectName('');
        setQuotedValue('');
        setTypeService('');
        setLeadType('');
        setAssignedGroup('');
        setSource('');
        setFollowupDate('');
        setShowEdit(false);
        setTimeout(() => {
            window.location.reload();
        }, 100);
    };

    const deleteLeadReport = async (id) => {
        try {
            const response = await axios.delete(`https://qtracker.site:9001/api/leads/${id}`);
            if (response.status === 200) {
                enqueueSnackbar('Report deleted successfully!', { variant: 'success' });
                setFilteredLeadReports(prevReports => prevReports.filter(report => report.id !== id));
            } else {
                enqueueSnackbar('Failed to employee!', { variant: 'error' });
            }
        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar('Connection Error!', { variant: 'error' });
        }
    };

    useEffect(() => {
        setFilteredLeadReports(LeadReports);
    }, [LeadReports]);


    const [GroupNames, setGroupNames] = useState([]);
    useEffect(() => {
        const fetchGroupName = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/groups');
                if (response.status === 200) {
                    setGroupNames(response.data);
                } else {
                    enqueueSnackbar('Failed to fetch GroupNames', { variant: 'error' });
                }
            } catch (error) {
                console.error('Error fetching GroupNames:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchGroupName();
    }, [enqueueSnackbar]);



    const [LeadTypes, setLeadTypes] = useState([]); 
    useEffect(() => {
        const fetchLeadTypes = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/LeadTypes');
                if (response.status >= 200 && response.status < 300) {
                    setLeadTypes(response.data);
                } else {
                    console.log('Failed to fetch Data');
                }
            } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchLeadTypes();
    }, [enqueueSnackbar]);

    const [statuses, setStatuses] = useState([]); 
    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/Status');
                if (response.status >= 200 && response.status < 300) {
                    setStatuses(response.data);
                } else {
                    enqueueSnackbar('Failed to fetch Status', { variant: 'error' });
                }
            } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchStatus();
    }, [enqueueSnackbar]);


    
    
     
    
      


    useEffect(() => {
        // Function to aggregate status data
        const aggregateStatusData = () => {
            const statusMap = new Map();
            let totalStatusAmount = 0;
            let totalCount = 0; // Initialize total count
    
            filteredLeadReports.forEach(LeadReport => {
                const status = LeadReport.Status;
                const count = statusMap.has(status) ? statusMap.get(status).count + 1 : 1;
                const amount = parseFloat(LeadReport.QuotedValue);
                totalStatusAmount += amount;
                totalCount++; // Increment total count
                // Update statusMap with both count and amount
                statusMap.set(status, { count, amount: (statusMap.get(status)?.amount || 0) + amount });
            });
    
            // Convert map to array for rendering
            const potentialStatusArray = Array.from(statusMap, ([status, { count, amount }]) => ({
                status,
                count,
                amount,
           //   percentage: ((amount / totalStatusAmount) * 100).toFixed(2), // Calculate percentage based on amount
            }));
            setPotentialStatus(potentialStatusArray);
            setTotalStatusAmount(totalStatusAmount); // Set the total status amount
            setTotalStatusCount(totalCount); // Set the total status count
        };
    
        aggregateStatusData();
    }, [filteredLeadReports]);
    
    useEffect(() => {
        // Function to aggregate source data
        const aggregateSourceData = () => {
            const sourceMap = new Map();
            let totalSourceAmount = 0;
            let totalCount = 0; // Initialize total count
    
            filteredLeadReports.forEach(LeadReport => {
                const source = LeadReport.Source;
                const count = sourceMap.has(source) ? sourceMap.get(source).count + 1 : 1;
                const amount = parseFloat(LeadReport.QuotedValue);
                totalSourceAmount += amount;
                totalCount++; // Increment total count
                // Update sourceMap with both count and amount
                sourceMap.set(source, { count, amount: (sourceMap.get(source)?.amount || 0) + amount });
            });
    
            // Convert map to array for rendering
            const potentialSourceArray = Array.from(sourceMap, ([source, { count, amount }]) => ({
                source,
                count,
                amount,
                percentage: ((amount / totalSourceAmount) * 100).toFixed(2), // Calculate percentage based on amount
            }));
            setPotentialSource(potentialSourceArray);
            setTotalSourceAmount(totalSourceAmount); // Set the total source amount
            setTotalSourceCount(totalCount); // Set the total source count
        };
    
        aggregateSourceData();
    }, [filteredLeadReports]);

    useEffect(() => {
        // Function to aggregate probability data
        const aggregateProbabilityData = () => {
            const probMap = new Map();
            let totalProbAmount = 0;
            let totalCount = 0; // Initialize total count
    
            filteredLeadReports.forEach(LeadReport => {
                const prob = LeadReport.Probability;
                if (!prob) return; // Skip if probability is empty or undefined
    
                const count = probMap.has(prob) ? probMap.get(prob).count + 1 : 1;
                const amount = parseFloat(LeadReport.QuotedValue);
                totalProbAmount += amount;
                totalCount++;
                probMap.set(prob, { count, amount: (probMap.get(prob)?.amount || 0) + amount });
            });
    
            // Convert map to array for rendering
            const potentialProbabilityArray = Array.from(probMap, ([prob, { count, amount }]) => ({
                prob,
                count,
                amount,
           //  percentage: ((amount / totalProbAmount) * 100).toFixed(2),
            }));
            setProbabilityStatus(potentialProbabilityArray);
            setTotalProbabilityAmount(totalProbAmount);
            setTotalProbabilityCount(totalCount);
        };
    
        aggregateProbabilityData();
    }, [filteredLeadReports]);
    
    
    useEffect(() => {
        // Create new chart for potential status
        const ctxStatus = document.getElementById('statusPieChart');
        if (statusChartRef.current) {
            statusChartRef.current.destroy();
        }
        statusChartRef.current = new Chart(ctxStatus, {
            type: 'bar',
            data: {
                labels: potentialStatus.map(item => item.status),
                datasets: [{
                    label: 'Amount',
                    data: potentialStatus.map(item => item.amount.toFixed(2)),
                    backgroundColor: [
                        'darkorange',
                        'lightblue',
                        'yellowgreen',
                        'crimson',
                        'rgba(153, 102, 255, 0.6)',
                        'rgba(255, 159, 64, 0.6)',
                        'blue',
                        'purple',
                        'gold',
                        'black',
                    ],
                }],
            },
          /*  options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins:{
                    legend: {
                        display: false, // This will hide the legend completely
                    },
                    tooltip: {
                        enabled: false, // Disables tooltips entirely
                    },
                    datalabels: {
                        display: false, // Ensures data labels inside the chart are hidden
                    },
                },
            },*/

            options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
    y: {
      display: false // This will hide the y-axis
    }
  }
        
       /* plugins: {
            legend: {
                display: false, // Disable the legend
            },
            datalabels: {
                display: false, // If you’re using datalabels plugin
            },
        },*/
    },
        });
    
        // Create new chart for potential source
        const ctxSource = document.getElementById('sourcePieChart');
        if (sourceChartRef.current) {
            sourceChartRef.current.destroy();
        }
        sourceChartRef.current = new Chart(ctxSource, {
            type: 'bar',
            data: {
                labels: potentialSource.map(item => item.source),
                datasets: [{
                    label: 'Amount',
                    data: potentialSource.map(item => item.amount.toFixed(2)),
                    backgroundColor: [
                        'purple',
                        'crimson',
                        'darkorange',
                        'yellowgreen',
                        'rgba(153, 102, 255, 0.6)',
                        'rgba(255, 159, 64, 0.6)',
                        'blue',
                        'lightblue',
                        'gold',
                        'black',
                    ],
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
    y: {
      display: false // This will hide the y-axis
    }
  }
            },
        });

        // Create new chart for potential probability
        const ctxProb = document.getElementById('probPieChart');
        if (probChartRef.current) {
            probChartRef.current.destroy();
        }
        probChartRef.current = new Chart(ctxProb, {
            type: 'pie',
            data: {
                labels: probabilityStatus.map(item => item.prob),
                datasets: [{
                    label: 'Amount',
                    data: probabilityStatus.map(item => item.amount.toFixed(2)),
                    backgroundColor: [
                        'yellowgreen',
                        'crimson',
                        'darkorange',
                        'yellowgreen',
                        'rgba(153, 102, 255, 0.6)',
                        'rgba(255, 159, 64, 0.6)',
                        'blue',
                        'lightblue',
                        'gold',
                        'black',
                    ],
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
    y: {
      display: false // This will hide the y-axis
    }
  }
            },
        });
    
        // Cleanup function to destroy chart instance on unmount
        return () => {
            if (statusChartRef.current) {
                statusChartRef.current.destroy();
            }
            if (sourceChartRef.current) {
                sourceChartRef.current.destroy();
            }
            if (probChartRef.current) {
                probChartRef.current.destroy();
            }
        };
    }, [potentialStatus, potentialSource, probabilityStatus]);


    return (
        <>
            <div className='fixed flex justify-end z-10 py-3 w-full shadow-2xl overflow-hidden text-white bg-black'>
                <div className='cursor-pointer mr-4'>
                    <FaUserCircle onClick={logout} size={28}/>
                </div>
                {showLogout && (
                <>
                    <div className='fixed text-black text-center px-2 w-1/12 shadow-2xl overflow-hidden bg-white border border-gray-400 mr-1 mt-10 z-50 justify-end rounded-lg'>
                        <h1 className='px-6 py-2 rounded-md my-1'>{name}</h1>
                        <h1 onClick={logoutUser} className='flex px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white'><MdLogout size={24}/> Logout</h1>
                    </div>
                </>
                )}
            </div>

            <div className='fixed z-20 shadow-2xl overflow-hidden flex flex-col min-h-screen w-1/6 bg-black text-white' style={{width:"310px"}}>
                <img className='p-4' src={Q} alt="Q-Trackr Logo"  />
                <h1 onClick={toggleLeadTracker} className='flex grid-cols-2 p-2 cursor-pointer bg-white mx-3 rounded-md my-3 text-black'><SiPivotaltracker size={24}/>&nbsp;Lead Tracker {leadTracker ? <IoIosArrowUp style={{ alignItems: 'center', marginLeft: '60px' }} size={24} /> : <IoIosArrowDown style={{ alignItems: 'center', marginLeft: '60px' }} size={24} />}</h1>
                {leadTracker && (
                    <div className='ml-3'>
                        <h1 onClick={dash} className='p-1 cursor-pointer bg-white mx-3 flex rounded-md mb-1 text-black'><MdDashboard style={{ alignItems: 'center' }} size={24} />&nbsp;Dashboard</h1>
                        <h1 onClick={addLead} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><MdAddBox style={{ alignItems: 'center' }} size={24} />&nbsp;Add Lead</h1>
                        <h1 onClick={leadReport} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><BiSolidReport style={{ alignItems: 'center' }} size={24} />&nbsp;Lead Reports</h1>
                    </div>
                )}
                <h1 onClick={toggleContact} className='flex p-2 cursor-pointer hover:bg-white mx-3 rounded-md my-2 hover:text-black'><MdContacts size={24}/>&nbsp; Contact Us&nbsp;&nbsp;{Contact ? <IoIosArrowUp style={{ alignItems: 'center', marginLeft: '60px' }} size={24} /> : <IoIosArrowDown style={{ alignItems: 'center', marginLeft: '60px' }} size={24} />}</h1>
                { Contact && (
                    <>
                        <div className='ml-3'>
                            <h1 onClick={sendEmail} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md hover:text-black'><IoIosMail style={{ alignItems: 'center' }} size={24} />&nbsp;info@dext.site</h1>
                        </div>
                    </>
                )}
                <div style={{backgroundColor:"#fff",position: "absolute",bottom: "0px"}}>
                <img className='p-4 mt-auto bg-white' src={loram} alt="Loram Logo" />
                </div>
                
            </div>

            {/* <div className='ml-80 p-2 flex mb-16 w-3/4 relative justify-center top-24'>
                <label htmlFor="filer">Filter:</label>
                <select name="filter" id="filterDash">
                        <option value="" disabled>Select Group</option>
                        <option value="All">All</option>
                    {GroupNames.map(GroupName => (
                        <option key={GroupName.id} value={GroupName.newGroupName}>{GroupName.newGroupName}</option>
                    ))}
                </select>
            </div> */}

           {/* <div className='ml-80 p-2 flex mb-16 w-3/4 relative justify-center top-24'>
                <div className='border p-2 w-full bg-white border-gray-200 overflow-hidden shadow-lg mx-2'>
                    <h1 className="px-3 text-center text-lg font-bold bg-rose-600 text-white py-2 mb-3">Total</h1><hr />
                    <h1 className="px-3 grid grid-cols-2 gap-x-12 py-2">Lead Count:<span className='font-bold text-4xl'>{totalStatusCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></h1>
                    <h1 className="px-3 text-left font-extrabold text-xl pt-4">${totalStatusAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h1>
                    <h1 className="px-3 text-center pt-3">100 %</h1>
                </div>
                {potentialStatus.map((item, index) => (
                    <div className='border w-full p-2 text-md bg-white mx-2 border-gray-200 overflow-hidden shadow-lg' key={index}>
                        <div>
                            <h1 className="px-3 text-center py-2 bg-black text-lg font-bold text-white mb-3">{item.status}</h1><hr />
                        </div>
                        <div>
                            <h1 className="px-3 grid grid-cols-2 gap-x-12 py-2">Lead Count:<span className='font-bold text-4xl'>{item.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></h1>
                        </div>
                        <div>
                            <h1 className="px-3 text-left font-extrabold text-xl pt-4">${item.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h1>
                        </div>
                        <div>
                            <h1 className="px-3 text-center pt-3">{item.percentage} %</h1>
                        </div>
                    </div>
                ))}
            </div>*/}


            
            {/*edit end for  */}

<div className='ml-80 p-4 relative overflow-hidden shadow-lg w-3/4 flex items-center top-16'>
                <div className='flex flex-col'>
                    <h1 className='text-xl font-semibold mb-2'>GROUP</h1>
                    <div className='flex items-center space-x-2'>
                        <select
                            className='border border-gray-300 rounded-md shadow-md outline-none'
                            value={groupFilter}
                            onChange={(e) => setGroupFilter(e.target.value)}
                            style={{
                                backgroundColor: '#f8f5fc',  // Light background color for dropdown
                                width: '200px',              // Set the width
                                height: '40px',              // Set the height
                                fontSize: '16px',            // Adjust the font size
                                padding: '8px'               // Adjust padding
                            }}
                        >

                            <option value="">--All--</option>
                            {GroupNames.map(group => (
                                <option key={group.id} value={group.newGroupName}>{group.newGroupName}</option>
                            ))}
                        </select>
                        <button
                            className='bg-green-600 hover:bg-green-800 text-white rounded-md text-sm'
                            onClick={handleSubmit} // Submit handler
                            style={{
                                width: '100px',              // Set the width
                                height: '40px',              // Set the height
                                fontSize: '16px',            // Adjust the font size
                                padding: '8px 16px'          // Adjust padding
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>

            <div className='ml-80 p-4 relative overflow-hidden shadow-lg w-3/4 justify-center top-16'>
                <div className='grid grid-cols-5 gap-4'>
                    <div className='bg-white border rounded-2xl shadow-md overflow-hidden '>
                        <div className='bg-purple-500 text-white text-left  p-2'>
                            <h2 className='text-lg font-semibold'>ALL</h2>
                        </div>
                        <div className='p-4 flex justify-between w-full'>
                            <div className='flex-col'>
                                <div className='flex gap-8'> <p className='text-sm'>Lead Counts</p>
                                    <h1 className='text-2xl font-bold'>{LeadCount}</h1> </div>
                                <div className='flex gap-4'>  <p className='text-lg mt-2'>${totalQuotedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                    <div className='' style={{ paddingLeft: '100px' }}>
                                        <img src='https://qtrackr.com/storage/images/2.jpeg' alt='handshake icon' className='w-12 h-9 ml-15 mt-2' />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='bg-white border rounded-2xl shadow-md overflow-hidden'>
                        <div className='bg-green-700 text-white text-left p-2'>
                            <h2 className='text-lg font-semibold'>Won</h2>
                        </div>
                        <div className='p-4 flex justify-between items-center'>
                            <div className='flex-col'>
                                <div className='flex gap-8'> <p className='text-sm'>Lead Counts</p>
                                    <h1 className='text-2xl font-bold'>{wonLeadCount}</h1> </div>
                                <div className='flex gap-4'>  <p className='text-lg mt-2'>${wonTotalQuotedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                    <div className=' ' style={{ paddingLeft: '130px' }}>
                                        <img src='https://qtrackr.com/storage/images/2.jpeg' alt='handshake icon' className='w-12 h-9 ml-5 mt-2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-white border rounded-2xl shadow-md overflow-hidden'>
                        <div className='bg-blue-700 text-white text-left p-2'>
                            <h2 className='text-lg font-semibold'>Won Recurring</h2>
                        </div>
                        <div className='p-4 flex justify-between items-center'>
                            <div className='flex-col'>
                                <div className='flex gap-8'> <p className='text-sm'>Lead Counts</p>
                                    <h1 className='text-2xl font-bold'>{wonRecurringLeadCount}</h1> </div>
                                <div className='flex gap-4'>  <p className='text-lg mt-2'>${wonRecurringTotalQuotedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                    <div className=' ' style={{ paddingLeft: '90px' }}>
                                        <img src='https://qtrackr.com/storage/images/2.jpeg' alt='handshake icon' className='w-12 h-9 ml-5 mt-2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-white border rounded-2xl shadow-md overflow-hidden'>
                        <div className='bg-yellow-400 text-white text-left p-2'>
                            <h2 className='text-lg font-semibold'>Pending</h2>
                        </div>
                        <div className='p-4 flex justify-between items-center'>
                            <div className='flex-col'>
                                <div className='flex gap-8'> <p className='text-sm'>Lead Counts</p>
                                    <h1 className='text-2xl font-bold'>{pendingLeadCount}</h1> </div>
                                <div className='flex gap-4'>  <p className='text-lg mt-2'>${pendingTotalQuotedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                    <div className=' ' style={{ paddingLeft: '100px' }}>
                                        <img src='https://qtrackr.com/storage/images/2.jpeg' alt='handshake icon' className='w-12 h-9 ml-5 mt-2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-white border rounded-2xl shadow-md overflow-hidden'>
                        <div className='bg-red-700 text-white text-left p-2'>
                            <h2 className='text-lg font-semibold'>Lost</h2>
                        </div>
                        <div className='p-4 flex justify-between items-center'>

                            <div className='flex-col'>
                                <div className='flex gap-8'> <p className='text-sm'>Lead Counts</p>
                                    <h1 className='text-2xl font-bold'>{lostLeadCount}</h1> </div>
                                <div className='flex gap-4'>  <p className='text-lg mt-2'>${lostTotalQuotedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                    <div className=' ' style={{ paddingLeft: '150px' }}>
                                        <img src='https://qtrackr.com/storage/images/6.jpeg' alt='handshake icon' className='w-8 h-8 ml-5 mt-2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
{/*edit end for  */}







            { showEdit && (
            <div className='ml-80 p-4 bg-white mb-16  border border-gray-200 relative overflow-hidden shadow-lg w-3/4 justify-center top-16'>
                <h1 className='text-2xl mb-2 font-semibold'>Edit Follow Ups</h1><hr /><hr /><br />
                <div className='flex justify-start w-full'>
                    <div className='mx-2 w-full'>
                        <label className='block' htmlFor="Name">Client Name:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Name' value={ClientName} onChange={(e) => setClientName(e.target.value)}/>
                    </div>
                    <div className='mx-2 w-full'>
                        <label className='block' htmlFor="date">Lead Date:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="date" id="date" name="date" value={LeadDate} onChange={(e) => setLeadDate(e.target.value)}/>
                    </div>
                    <div className='mx-2 w-full'>
                        <label className='block' htmlFor="Name">Project Name:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Project Name' value={ProjectName} onChange={(e) => setProjectName(e.target.value)}/>
                    </div>
                    <div className='mx-2 w-full'>
                        <label className='block' htmlFor="Name">Quoted Value:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="number" placeholder='Enter Quoted Value' inputMode="numeric" pattern="[0-9]*" value={QuotedValue} onChange={(e) => setQuotedValue(e.target.value)}/>
                    </div>
                </div>
                <br />
                <div className='flex justify-start w-full'>
                    <div className='mx-2 w-full'>
                        <label className='block' htmlFor="Name">Group Assigned:</label>
                        <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={AssignedGroup} onChange={(e) => setAssignedGroup(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Select Group</option>
                            {GroupNames.map(group => (
                                <option key={group.id} value={group.newGroupName}>{group.newGroupName}</option>
                            ))}
                        </select>
                    </div>
                    <div className='mx-2 w-full'>
                        <label className='block' htmlFor="Name">Lead Type:</label>
                        <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={LeadType} onChange={(e) => setLeadType(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Select Lead Type</option>
                            {LeadTypes.map(LeadTyp => (
                                <option key={LeadTyp.id} value={LeadTyp.LeadType}>{LeadTyp.LeadType}</option>
                            ))}
                        </select>
                    </div>
                    <div className='mx-2 w-full'>
                        <label className='block' htmlFor="Name">Status:</label>
                        <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={Status} onChange={(e) => setStatus(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Select Status</option>
                            {statuses.map(statuse => (
                                <option key={statuse.id} value={statuse.status}>{statuse.status}</option>
                            ))}
                        </select>
                    </div>
                    <div className='mx-2 w-full'>
                        <label className='block' htmlFor="date">Next Follow-up Date:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="date" id="date" name="date" value={FollowupDate} onChange={(e) => setFollowupDate(e.target.value)}/>
                    </div>
                </div>
                <br />
                <div className='flex mt-14 justify-center'>
                    <button onClick={Submit} className='mx-2 py-2 px-5 bg-blue-600 hover:bg-blue-900 overflow-hidden shadow-md text-white rounded-sm'>Confirm</button>
                    <button onClick={Cancel} className='mx-2 py-2 px-5 bg-black hover:bg-white hover:text-black border border-black overflow-hidden shadow-md text-white rounded-sm'>Cancel</button>
                </div>
                <br />
            </div>
            )}


            <div className='ml-80 p-4 bg-white  border border-gray-200 relative overflow-hidden shadow-lg w-3/4 justify-center top-16'>
                <div className='flex'>
                <h1 className='text-2xl w-4/5 mb-2 font-semibold'>Follow Ups</h1>
                <div className='flex w-1/5'>
                    <MdSearch size={40}/>
                    <input type="text" value={searchQuery} onChange={handleSearchInputChange} className='border w-full border-gray-300 rounded-md mb-2 p-2 overflow-hidden shadow-md outline-none' placeholder='Search' />
                </div>
                </div>  
                <hr /><hr /><br />
                <div className="max-h-[500px] overflow-auto">
                <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-black sticky top-0">
                            <tr className='text-white'>
                                <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">#</th>
                                <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Lead Date</th>
                                <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Client Name</th>
                                <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Project Name</th>
                                <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Lead type</th>
                                <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Quoted Value</th>
                                <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Status</th>
                                <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Group</th>
                                <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Followup Date</th>
                                <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Attachment</th>
                                <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Edit</th>
                                <th className="px-8 py-3 text-center border text-sm font-medium uppercase tracking-wider"><FaTrash size={18}/></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {filteredLeadReports.map(LeadReport => (
                            <tr className={`border text-sm ${getRowClass2(LeadReport.Status)}`} key={LeadReport.id}>
                                    <td className="px-3 text-center py-2">{LeadReport.id}</td>
                                    <td className="px-3 text-center py-2">{LeadReport.LeadDate ? formatDate(LeadReport.LeadDate) : 'N/A'}</td>
                                    <td className="px-3 text-center py-2">{LeadReport.ClientName}</td>
                                    <td className="px-3 text-center py-2">{LeadReport.ProjectName}</td>
                                    <td className="px-3 text-center py-2">{LeadReport.LeadType}</td>
                                    <td className="px-3 text-center py-2">${LeadReport.QuotedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td className="px-3 text-center py-2">{LeadReport.Status}</td>
                                    <td className="px-3 text-center py-2">{LeadReport.AssignedGroup}</td>
                                    <td className="px-3 text-center py-3">{LeadReport.FollowupDate ? formatDate(LeadReport.FollowupDate) : 'N/A'}</td>

                                    <td className="flex py-2 ml-1">
                                        {/* Hidden File Input */}
                                        <input
                                            type="file"
                                            accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
                                            onChange={(e) => handleFileUpload(e, LeadReport.id)}
                                            id={`file-input-${LeadReport.id}`}
                                            className="hidden"
                                        />

                                        {/* Custom File Upload UI */}
                                        <label
                                            htmlFor={`file-input-${LeadReport.id}`}
                                            className="bg-gray-200 text-sm px-0.5 h-7   flex rounded border cursor-pointer hover:bg-gray-300"
                                            style={{ width: "80px" }}
                                        >
                                            {LeadReport.attachment
                                                ? "Change File"
                                                : "Choose File"}
                                        </label>

                                        {LeadReport.attachment && (
                                            <div className="flex flex-col ml-2">

                                                {/*<button
                                                    className="bg-blue-500 text-white px-1 py-1 rounded hover:bg-blue-700 mt-1"
                                                    onClick={() =>
                                                        downloadFile(LeadReport.attachment.split('\\').pop().split('/').pop())
                                                    }
                                                >
                                                    Download
                                                </button>*/}
                                                <a
                                                    href={`https://qtracker.site:9001/api/uploads/${LeadReport.attachment
                                                        .split('\\')
                                                        .pop()
                                                        .split('/').pop()}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 underline text-sm mt-1"
                                                >
                                                    <img src={icon} alt='file' width="16" height="16" title="Attachment" />
                                                </a>
                                            </div>
                                        )}
                                    </td>

                                    <td onClick={() => editLeadReport(LeadReport.id)} className="px-4 hover:bg-black cursor-pointer hover:text-white text-center py-2"><MdEditSquare size={20}/></td>
                                    <td onClick={() => deleteLeadReport(LeadReport.id)} className="px-8 hover:bg-black cursor-pointer hover:text-white text-center py-2"><FaTrash size={18}/></td>
                                </tr>
                            ))}
                        </tbody>
                </table>
                </div>

                   {/* Pagination Controls */}
                <div className="flex justify-between items-center mt-4">
                    <div>
                        Showing {startIdx + 1} to {Math.min(endIdx, totalItems)} of {totalItems} entries
                    </div>
                    <div className="space-x-2">
                        <button onClick={handlePrevious} disabled={currentPage === 1} className="px-2 py-1 rounded">
                            Previous
                        </button>
                        {[...Array(totalPages).keys()].map(page => (
                            <button
                                key={page + 1}
                                onClick={() => handlePageChange(page + 1)}
                                className={`px-2 py-1 rounded ${currentPage === page + 1 ? 'bg-gray-300' : ''}`}
                            >
                                {page + 1}
                            </button>
                        ))}
                        <button onClick={handleNext} disabled={currentPage === totalPages} className="px-2 py-1 rounded">
                            Next
                        </button>
                    </div>
                </div>


            </div> 
            <br /><br />

            <div className='ml-80 p-4 bg-white border border-gray-200 relative overflow-hidden shadow-lg w-3/4 justify-center top-24' style={{ fontFamily: 'Lora', fontWeight: 600 }}>
                <div className='flex'>
                    <h1 className='text-2xl w-4/5 mb-2 font-semibold'>POTENTIAL STATUS </h1>
                </div>  
                <hr /><hr /><br />


                <div className=' flex items-center mb-4'>
                    <label className='bg-gray-500 text-white px-1.5 py-1 rounded-md text-xs' >From</label>
                    <DatePicker
                        selected={statusFilterDateFrom}
                        onChange={(date) => setStatusFilterDateFrom(date)}
                        dateFormat="MM-dd-yyyy" // Customize the format as per your requirement
                        className="border w-28 h-7  border-gray-400 rounded-md p-1.5 overflow-hidden shadow-md outline-none"
                        placeholderText="mm-dd-yyyy" // Placeholder

                    />
                    <label className='bg-gray-500 text-white px-1.5 py-1 rounded-md ml-2 text-xs' >To</label>
                    <DatePicker
                        selected={statusFilterDateTo}
                        onChange={(date) => setStatusFilterDateTo(date)}
                        dateFormat="MM-dd-yyyy" // Customize the format as per your requirement
                        className="border w-28 h-7 border-gray-400 rounded-md p-1.5 overflow-hidden shadow-md outline-none"
                        placeholderText="mm-dd-yyyy" // Placeholder

                    />

                    <div className='mx-3 w-38 h-7'>


                        <select onClick={Prob} className='border w-38 border-gray-400 rounded-md p-0.5 overflow-hidden shadow-md outline-none' value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} name="auth" id="auth">
                            <option value="" disabled className='text-xs'>Select Status</option>
                            <option className='text-xs' value="all" >All</option>
                            {statuses.map(statuse => (
                                <option className='text-xs' key={statuse.id} value={statuse.status}>{statuse.status}</option>
                            ))}
                        </select>


                    </div>

                    <div>
                        <button className='text-white bg-green-600 py-1 px-2 text-sm rounded-lg' onClick={handlePotentialStatusFilter}>SUBMIT</button>
                    </div>

                </div>



                <div className="max-h-[300px] overflow-auto flex">
                    <div className='w-1/2'>
                        <div className="max-h-[300px] overflow-auto">
                        <table className="w-full divide-y divide-gray-200">
                            <thead className="bg-black sticky top-0">
                                <tr className='text-white'>
                                    {/*<th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">#</th>*/}
                                    <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Status</th>
                                    <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Count</th>
                                    <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Amount</th>
                                    {/*<th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">%</th>*/}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {potentialStatus.sort((a, b) => {
                                        const statusOrder = ['WON', 'WON RECURRING', 'PENDING', 'LOST']; // Define desired order
                                        return statusOrder.indexOf(a.status.toUpperCase()) - statusOrder.indexOf(b.status.toUpperCase());
                                    }).map((item, index) => (
                                    <tr className='border text-md' key={index}>
                                        {/*<td className="px-3 text-center py-2">{index + 1}</td>*/}
                                        <td className="px-3 text-left py-2">{item.status.toUpperCase()}</td>
                                        <td className="px-3 text-center py-2">{item.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                        <td className="px-3 text-right py-2">${item.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                        {/*<td className="px-3 text-center py-2">{item.percentage} %</td>*/}
                                    </tr>
                                ))}
                                {/* Display total row */}
                                <tr className='border bg-gray-200 font-bold text-md'>
                                    {/*<td className="px-3 text-center py-2" colSpan="1">#</td>*/}
                                    <td className="px-3 text-left py-2">Total</td>
                                    <td  className="px-3 text-center py-2"></td>
                                    {/*<td className="px-3 text-center py-2">{totalStatusCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>*/}
                                    <td className="px-3 text-right py-2">${totalStatusAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    {/*<td className="px-3 text-center py-2">100 %</td>*/}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='w-1/2'>
                    <canvas id="statusPieChart" width="300" height="300"></canvas>
                </div>
                </div>
            </div> 
            <br /><br />

            

            <div className='ml-80 p-4 bg-white border border-gray-200 relative overflow-hidden shadow-lg w-3/4 justify-center top-24' style={{ fontFamily: 'Lora', fontWeight: 600 }}>
                <div className='flex'>
                    <h1 className='text-2xl w-4/5 mb-2 font-semibold'>PROBABILITY STATUS   </h1>
                </div>  
                <hr /><hr /><br />
                <div className="max-h-[300px] overflow-auto flex">
                    <div className='w-1/2'>

 {/* start of filter */}
                    <div className=' flex items-center'>
                            <label className='bg-gray-500 text-white px-1.5 py-1 rounded-md text-xs' >From</label>
                            <DatePicker
                                selected={probabilityFilterDateFrom}
                                onChange={(date) => setProbabilityFilterDateFrom(date)}
                                dateFormat="MM-dd-yyyy" // Customize the format as per your requirement
                                className="border w-28 h-7  border-gray-400 rounded-md p-1.5 overflow-hidden shadow-md outline-none"
                                placeholderText="mm-dd-yyyy" // Placeholder

                            />
                            <label className='bg-gray-500 text-white px-1.5 py-1 rounded-md ml-2 text-xs' >To</label>
                            <DatePicker
                                selected={probabilityFilterDateTo}
                                onChange={(date) => setProbabilityFilterDateTo(date)}
                                dateFormat="MM-dd-yyyy" // Customize the format as per your requirement
                                className="border w-28 h-7 border-gray-400 rounded-md p-1.5 overflow-hidden shadow-md outline-none"
                                placeholderText="mm-dd-yyyy" // Placeholder

                            />

                            <div className='mx-3 '>

                                <select className='border w-36 border-gray-400 rounded-md p-0.5 overflow-hidden shadow-md outline-none' value={AssignedGroup} onChange={(e) => setAssignedGroup(e.target.value)} name="auth" id="auth">
                                    <option className=' text-xs' value="" disabled>Select Group</option>
                                    <option value="all" >All</option>
                                    {GroupNames.map(group => (
                                        <option key={group.id} value={group.newGroupName}>{group.newGroupName}</option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <button className='text-white bg-green-600 py-1 px-2 text-sm rounded-lg relative inline-block' onClick={handleFilter}>SUBMIT</button>

                            </div>

                        </div>
                        {/* end of filter */}
                         

                        <div className="max-h-[300px] overflow-auto pt-4" >
                        <table className="w-full divide-y divide-gray-200">
                            <thead className="bg-black sticky top-0">
                                <tr className='text-white'>
                                    {/*<th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">#</th>*/}
                                    <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Probability</th>
                                    <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Count</th>
                                    <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Amount</th>
                                    {/*<th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">%</th>*/}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {probabilityStatus
                                    .sort((a, b) => {
                                            const probOrder = ['HIGH', 'MEDIUM', 'LOW']; // Define the desired order
                                            return probOrder.indexOf(a.prob.toUpperCase()) - probOrder.indexOf(b.prob.toUpperCase());
                                        })
                                .map((item, index) => (
                                    <tr className='border text-md' key={index}>
                                        {/*<td className="px-3 text-center py-2">{index + 1}</td>*/}
                                        <td className="px-3 text-left py-2">{item.prob.toUpperCase()}</td>
                                        <td className="px-3 text-center py-2">{item.count}</td>
                                        <td className="px-3 text-right py-2">${item.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                        {/*<td className="px-3 text-center py-2">{item.percentage} %</td>*/}
                                    </tr>
                                ))}
                                {/* Display total row */}
                                <tr className='border bg-gray-200 font-bold text-md'>
                                    {/*<td className="px-3 text-center py-2" colSpan="1">#</td>*/}
                                    <td className="px-3 text-left py-2">Total</td>
                                    <td  className="px-3 text-center py-2"></td>
                                    {/*<td className="px-3 text-center py-2">{totalProbabilityCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>*/}
                                    <td className="px-3 text-right py-2">${totalProbabilityAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    {/*<td className="px-3 text-center py-2">100 %</td>*/}
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <canvas id="probPieChart" width="300" height="300"></canvas>
                    </div>
                </div>
            </div> 
            <br /><br />

            <div className='ml-80 p-4 bg-white border border-gray-200 relative overflow-hidden shadow-lg w-3/4 justify-center top-24' style={{ fontFamily: 'Lora', fontWeight: 600 }}>
                <div className='flex'>
                    <h1 className='text-2xl w-4/5 mb-2 font-semibold'>LEAD SOURCE </h1>
                </div>  
                <hr /><hr /><br />


                <div className=' flex items-center mb-4'>
                    <label className='bg-gray-500 text-white px-1.5 py-1 rounded-md text-xs' >From</label>
                    <DatePicker
                        selected={sourceFilterDateFrom}
                        onChange={(date) => setSourceFilterDateFrom(date)}
                        dateFormat="MM-dd-yyyy" // Customize the format as per your requirement
                        className="border w-28 h-7  border-gray-400 rounded-md p-1.5 overflow-hidden shadow-md outline-none"
                        placeholderText="mm-dd-yyyy" // Placeholder

                    />
                    <label className='bg-gray-500 text-white px-1.5 py-1 rounded-md ml-2 text-xs' >To</label>
                    <DatePicker
                        selected={sourceFilterDateTo}
                        onChange={(date) => setSourceFilterDateTo(date)}
                        dateFormat="MM-dd-yyyy" // Customize the format as per your requirement
                        className="border w-28 h-7 border-gray-400 rounded-md p-1.5 overflow-hidden shadow-md outline-none"
                        placeholderText="mm-dd-yyyy" // Placeholder

                    />

                    <div className='mx-3 mb-0.5'>

                        <select className='border w-42  rounded-md  p-0.5 mt-1 overflow-hidden shadow-md outline-none' value={selectedSource} onChange={(e) => setSelectedSource(e.target.value)} name="auth" id="auth" required>
                            <option className='text-sm' value="" disabled>Select Source</option>
                            <option value="all" >All</option>
                            {Sources.map(Sourc => (
                                <option key={Sourc.id} value={Sourc.newSource}>{Sourc.newSource}</option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <button className='text-white bg-green-600 py-1 px-2 text-sm rounded-lg' onClick={handleLeadingSourceFilter}>SUBMIT</button>
                    </div>

                </div>



                <div className="max-h-[300px] overflow-auto flex">
                    <div className='w-1/2'>
                        <div className="max-h-[300px] overflow-auto">
                        <table className="w-full divide-y divide-gray-200">
                            <thead className="bg-black sticky top-0">
                                <tr className='text-white'>
                                    {/*<th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">#</th>*/}
                                    <th className="px-4 py-3 text-left border text-sm font-medium uppercase tracking-wider">Source</th>
                                    <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">Count</th>
                                    <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">%</th>
                                    <th className="px-4 py-3 text-right border text-sm font-medium uppercase tracking-wider">Amount</th>
                                    
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {potentialSource.map((item, index) => (
                                    <tr className='border text-md' key={index}>
                                        {/*<td className="px-3 text-center py-2">{index + 1}</td>*/}
                                        <td className="px-3 text-left py-2">{item.source.toUpperCase()}</td>
                                        <td className="px-3 text-center py-2">{item.count}</td>                                
                                        <td className="px-3 text-center py-2">{item.percentage} %</td>
                                        <td className="px-3 text-right py-2">${item.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    </tr>
                                ))}
                                {/* Display total row */}
                                <tr className='border bg-gray-200 font-bold text-md'>
                                    {/*<td className="px-3 text-center py-2" colSpan="1">#</td>*/}
                                    <td  className="px-3 text-left py-2">Total</td>
                                    <td  className="px-3 text-center py-2"></td>
                                    <td  className="px-3 text-center py-2"></td>
                                    {/*<td className="px-3 text-center py-2">{totalSourceCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>*/}
                                    <td className="px-3 text-right py-2">${totalSourceAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    {/*<td className="px-3 text-center py-2">100 %</td>*/}
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <canvas id="sourcePieChart" width="300" height="300"></canvas>
                    </div>
                </div>
            </div> 
            
            <br /><br /><br /><br /><br /><br />
        </>
    );
};

export default Dashboard1;

import React, { useState,useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { IoIosArrowDown, IoIosArrowUp, IoIosMail, IoIosPeople } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { SiPivotaltracker } from "react-icons/si";
import { MdDashboard, MdContacts, MdLogout, MdCategory, MdPending, MdGroupAdd } from "react-icons/md";
import { MdAddBox } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import { MdFolderCopy } from "react-icons/md";
import { FaServicestack } from "react-icons/fa6";
import { RiMastercardFill } from "react-icons/ri";
import { DiOpensource } from "react-icons/di";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import checkSessionValidity from "../../CheckSessionValidity";
import Q from "../../Assets/Qtrackr.png";
import loram from "../../Assets/loram.png";

const AddLead = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const token = localStorage.getItem('token');
    if (!token){
        enqueueSnackbar('Login to Navigate!',{variant:'error'});
        navigate('/');
    }

    const [leadTracker, setLeadTracker] = useState(true);
    const [masters, setMasters] = useState(false);
    const [Contact, setshowcontact] = useState(false);
    const [showLogout, setshowlogout] = useState(false);

    const toggleLeadTracker = (e) => {
        e.preventDefault();
        setLeadTracker(!leadTracker);
        setMasters(false);
        setshowcontact(false);
    };

    const toggleMasters = (e) => {
        e.preventDefault();
        setMasters(!masters);
        setshowcontact(false);
        setLeadTracker(false);
    };


    const toggleContact = (e) => {
        e.preventDefault();
        setshowcontact(!Contact);
        setLeadTracker(false);
        setMasters(false);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        window.location.href = 'mailto:info@dext.site?subject=Contact%20Us&body=Hello%2C%0D%0A%0D%0A';
    };

    const addLead = (e) => {
        e.preventDefault();
        navigate('/addlead_1');
    };
    const dash = (e) => {
        e.preventDefault();
        navigate('/dashboard_1');
    };
    const leadReport = (e) => {
        e.preventDefault();
        navigate('/leadreport_1');
    };
    const NavEmployees = (e) => {
        e.preventDefault();
        navigate('/employees_1');
    };
    const NavServiceType = (e) => {
        e.preventDefault();
        navigate('/service_type_1');
    };
    const NavLeadType = (e) => {
        e.preventDefault();
        navigate('/lead_type_1');
    };
    const NavStatus = (e) => {
        e.preventDefault();
        navigate('/status_1');
    };
    const NavSource = (e) => {
        e.preventDefault();
        navigate('/source_1');
    };
    const NavClientMaster = (e) => {
        e.preventDefault();
        navigate('/client_master_1');
    };
    const NavAddGrp = (e) => {
        e.preventDefault();
        navigate('/add_group_1');
    };

    const logout = (e) => {
        e.preventDefault();
        setshowlogout(!showLogout);
    };
    const logoutUser = (e) => {
        e.preventDefault();
        enqueueSnackbar('Successfully Logged out!', { variant: 'success'});
        localStorage.removeItem('token');
        navigate('/');
    };


    const [ClientName, setClientName] = useState('');
    const [TypeService, setTypeService] = useState('');
    const [LeadType, setLeadType] = useState('');
    const [AssignedGroup, setAssignedGroup] = useState('');
    const [QuotedValue, setQuotedValue] = useState('');
    const [ProjectName, setProjectName] = useState('');
    
    const [Reference, setReference] = useState('');
    const [ClientPhone, setClientPhone] = useState('');
    const [ClientContactName, setClientContactName] = useState('');
    const [ClientEmail, setClientEmail] = useState('');
    const [Status, setStatus] = useState('');
    const [Source, setSource] = useState('');
    const [FollowupDate, setFollowupDate] = useState('');
    const [Probability, setProbability] = useState('');
    const [, setShowProb] = useState(false);
    const [, setshowRef] = useState(false);
    const [todaysDate, setTodaysDate] = useState('');
    const [comments, setComments] = useState('');
   
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const Submit = async(e) => {
        e.preventDefault();
        if(!ClientName || !LeadType || ((Status !== "Won" && Status !== "Lost") && !FollowupDate) || !LeadDate || !Source || !ClientPhone || !ClientContactName || !TypeService || !Status || !ProjectName || !QuotedValue  ){
          enqueueSnackbar('Please fill all the fields!', { variant: 'error'});
        }  else if (!emailRegex.test(ClientEmail)) {
            // Show a message if the email format is invalid
            enqueueSnackbar('Please enter a valid email address!', { variant: 'error' });
        } else if((Status === "Pending" && !comments)){
             
            enqueueSnackbar('Please enter Comments!', { variant: 'error' });
        }else if((Source === "Reference" && !Reference)){
             
            enqueueSnackbar('Please enter Reference!', { variant: 'error' });
        }
        else{
            try {
                const response = await axios.post('https://qtracker.site:9001/api/leads', {
                  ClientName,
                  ClientEmail,
                  ProjectName,
                  LeadDate,
                  ClientPhone,
                  QuotedValue,
                  ClientContactName,
                  Status,
                  Source,
                  TypeService,
                  AssignedGroup,
                  LeadType,
                  FollowupDate,
                  Probability,
                  Reference
                });
          
                if (response.status >= 200 && response.status < 300) {
                  enqueueSnackbar('Lead Added Successfully!', { variant: 'success'});
                  setClientName('');
                  setLeadDate('');
                  setClientPhone('');
                  setClientContactName('');
                  setStatus('');
                  setClientEmail('');
                  setProjectName('');
                  setQuotedValue('');
                  setTypeService('');
                  setAssignedGroup('');
                  setLeadType('');
                  setFollowupDate('');
                  setShowProb(false);
                  setProbability('');
                  setSource('');
                  setshowRef(false);
                  setReference('');
                } else {
                  enqueueSnackbar('Please Check Your Connection!', { variant: 'error'});
                }
              } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!',{variant:'error'});
              }
        }
    };
    
    

    const Cancel = (e) => {
        e.preventDefault();
        setClientName('');
        setClientEmail('');
        setLeadDate('');
        setClientPhone('');
        setClientContactName('');
        setStatus('');
        setProjectName('');
        setQuotedValue('');
        setTypeService('');
        setLeadType('');
        setAssignedGroup('');
        setSource('');
        setFollowupDate('');
        setShowProb(false);
        setProbability('');
        setshowRef(false);
        setReference('');
    };



    const Prob = (e) => {
        e.preventDefault();
        if (Status === 'Pending'){
            setShowProb(true);
        }
        else{
            setShowProb(false);
        }
    };

    const Ref = (e) => {
        e.preventDefault();
        if (Source === 'Reference'){
            setshowRef(true);
        }
        else{
            setshowRef(false);
        }
    };


    const [GroupNames, setGroupNames] = useState([]);
    useEffect(() => {
        const fetchGroupName = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/groups');
                if (response.status === 200) {
                    setGroupNames(response.data);
                } else {
                    enqueueSnackbar('Failed to fetch GroupNames', { variant: 'error' });
                }
            } catch (error) {
                console.error('Error fetching GroupNames:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchGroupName();
    }, [enqueueSnackbar]);

    
    const handleVisibilityChange = useCallback(() => {
        if (!document.hidden) { // Fires only when the tab becomes visible
            const isSessionValid = checkSessionValidity();
            if (!isSessionValid) {
                navigate('/login'); // Redirect to login if session is invalid
            }
        }
    }, [navigate]);

    useEffect(() => {
        // Check session validity on component mount
        const isSessionValid = checkSessionValidity();
        if (!isSessionValid) {
            navigate('/login');
        }

        // Add event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup listener on unmount
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleVisibilityChange,navigate]);



    const [serviceTypes, setServiceTypes] = useState([]); 
    useEffect(() => {
        const fetchServiceTypes = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/serviceTypes');
                if (response.status >= 200 && response.status < 300) {
                    setServiceTypes(response.data);
                } else {
                    console.log('Failed to fetch Data');
                }
            } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchServiceTypes();
    }, [enqueueSnackbar]);


    const [Sources, setSources] = useState([]);
    useEffect(() => {
        const fetchSources = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/Source');
                if (response.status === 200) {
                    console.log(response.data);
                    setSources(response.data);
                } else {
                    enqueueSnackbar('Failed to fetch sources', { variant: 'error' });
                }
            } catch (error) {
                console.error('Error fetching sources:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchSources();
    }, [enqueueSnackbar]);

    console.log(Status);
    useEffect(() => {
        const today = new Date().toISOString().split("T")[0];
        setTodaysDate(today);
    }, []);


    const [LeadTypes, setLeadTypes] = useState([]); 
    useEffect(() => {
        const fetchLeadTypes = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/leadTypes');
                if (response.status >= 200 && response.status < 300) {
                    setLeadTypes(response.data);
                } else {
                    console.log('Failed to fetch Data');
                }
            } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchLeadTypes();
    }, [enqueueSnackbar]);

    const [statuses, setStatuses] = useState([]); 
    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await axios.get('https://qtracker.site:9001/api/Status');
                if (response.status >= 200 && response.status < 300) {
                    setStatuses(response.data);
                } else {
                    enqueueSnackbar('Failed to fetch Status', { variant: 'error' });
                }
            } catch (error) {
                console.error('Error:', error);
                enqueueSnackbar('Connection Error!', { variant: 'error' });
            }
        };
        fetchStatus();
    }, [enqueueSnackbar]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    };

    const [LeadDate, setLeadDate] = useState(() => formatDate(new Date()));

    
    

    function formatNumberWithCommas(number) {
        if (!number) return ''; // Handle case where number is undefined or null
        const parts = number.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join('.');
    }
    const name = localStorage.getItem('name');

    return (
        <>
            
            <div className='fixed flex justify-end z-10 shadow-2xl overflow-hidden py-3 w-full text-white bg-black'>
                <div className='cursor-pointer mr-4'>
                    <FaUserCircle onClick={logout} size={28}/>
                </div>
                {showLogout && (
                <>
                    <div className='fixed text-black text-center px-2 w-1/12 shadow-2xl overflow-hidden bg-white border border-gray-400 mr-1 mt-10 z-50 justify-end rounded-lg'>
                        <h1 className='px-6 py-2 rounded-md my-1'>{name}</h1>
                        <h1 onClick={logoutUser} className='flex px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white'><MdLogout size={24}/> Logout</h1>
                    </div>
                </>
                )}
            </div>

            <div className='fixed z-20 flex flex-col shadow-2xl overflow-hidden min-h-screen w-1/6 bg-black text-white' style={{width:"310px"}}>
                <img className='p-4' src={Q} alt="Q-Trackr Logo" />
                <h1 onClick={toggleLeadTracker} className='flex grid-cols-2 p-2 cursor-pointer bg-white mx-3 rounded-md my-3 text-black'><SiPivotaltracker size={24}/>&nbsp;Lead Tracker {leadTracker ? <IoIosArrowUp style={{ alignItems: 'center', marginLeft: '60px' }} size={24} /> : <IoIosArrowDown style={{ alignItems: 'center', marginLeft: '60px' }} size={24} />}</h1>
                {leadTracker && (
                    <div className='ml-3'>
                        <h1 onClick={dash} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><MdDashboard style={{ alignItems: 'center' }} size={24} />&nbsp;Dashboard</h1>
                        <h1 onClick={addLead} className='p-1 cursor-pointer bg-white mx-3 flex rounded-md mb-1 text-black'><MdAddBox style={{ alignItems: 'center' }} size={24} />&nbsp;Add Lead</h1>
                        <h1 onClick={leadReport} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><BiSolidReport style={{ alignItems: 'center' }} size={24} />&nbsp;Lead Reports</h1>

                    </div>
                )}
                <h1 onClick={toggleMasters} className='flex p-2 cursor-pointer hover:bg-white mx-3 rounded-md my-2 hover:text-black'><MdFolderCopy size={24}/>&nbsp; Masters&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{masters ? <IoIosArrowUp style={{ alignItems: 'center', marginLeft: '60px' }} size={24} /> : <IoIosArrowDown style={{ alignItems: 'center', marginLeft: '60px' }} size={24} />}</h1>
                {masters && (
                    <div className='ml-3'>
                        <h1 onClick={NavEmployees} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><IoIosPeople style={{ alignItems: 'center' }} size={24} />&nbsp;&nbsp;Employees</h1>
                        <h1 onClick={NavServiceType} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><FaServicestack style={{ alignItems: 'center' }} size={24} />&nbsp;&nbsp;Service Type</h1>
                        <h1 onClick={NavLeadType} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><MdCategory style={{ alignItems: 'center' }} size={24} />&nbsp;&nbsp;Lead Type</h1>
                        <h1 onClick={NavStatus} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><MdPending style={{ alignItems: 'center' }} size={24} />&nbsp;&nbsp;Status</h1>
                        <h1 onClick={NavSource} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><DiOpensource style={{ alignItems: 'center' }} size={24} />&nbsp;&nbsp;Source</h1>
                        <h1 onClick={NavClientMaster} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><RiMastercardFill style={{ alignItems: 'center' }} size={24} />&nbsp;&nbsp;Client Master</h1>
                        <h1 onClick={NavAddGrp} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black'><MdGroupAdd style={{ alignItems: 'center' }} size={24} />&nbsp;&nbsp;Add Group</h1>
                    </div>
                )}
                <h1 onClick={toggleContact} className='flex p-2 cursor-pointer hover:bg-white mx-3 rounded-md my-2 hover:text-black'><MdContacts size={24}/>&nbsp; Contact Us&nbsp;&nbsp;{Contact ? <IoIosArrowUp style={{ alignItems: 'center', marginLeft: '60px' }} size={24} /> : <IoIosArrowDown style={{ alignItems: 'center', marginLeft: '60px' }} size={24} />}</h1>
                { Contact && (
                    <>
                        <div className='ml-3'>
                            <h1 onClick={sendEmail} className='p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md hover:text-black'><IoIosMail style={{ alignItems: 'center' }} size={24} />&nbsp;info@dext.site</h1>
                        </div>
                    </>
                )}
                <img className='p-4 mt-auto bg-white' src={loram} alt="Loram Logo" />
            </div>

            <div className=' ml-64 p-4 pl-5 bg-white  border border-gray-200 relative overflow-hidden shadow-lg w-3/4 justify-center top-24' style={{marginLeft:"320px"}} >
                <h1 className='text-2xl mb-2 font-semibold'>Add Lead</h1><hr /><hr /><br />
                <div className='flex justify-start w-full'>
                    <div className="mx-3 w-full">
         <label className="block text-sm font-medium text-gray-700" htmlFor="date">
          Lead Date:
        <span className="text-red-700 text-xl">*</span>
      </label>
      <DatePicker
        selected={LeadDate}
        onChange={(date) => setLeadDate(date)}
        dateFormat="MM-dd-yyyy" // Custom format
        className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
        placeholderText="mm-dd-yyyy"
        maxDate={new Date()}
        required
      />
               </div>

                    <div className='mx-3 w-full '>
                        <label className='block' htmlFor="Name">Client Name:<span className='text-red-700 text-xl'>*</span></label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Name' value={ClientName} onChange={(e) => setClientName(e.target.value)} required/>
                    </div>

                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="Name">Source:<span className='text-red-500'>*</span></label>
                        <select  onClick={Ref} className='border w-full border-gray-400 rounded-md p-2.5 mt-1 overflow-hidden shadow-md outline-none' value={Source} onChange={(e) => setSource(e.target.value)} name="auth" id="auth" required>
                            <option value="" disabled>Select Source</option>
                            {Sources.map(Sourc => (
                                <option key={Sourc.id} value={Sourc.newSource}>{Sourc.newSource}</option>
                            ))}
                        </select>
                    </div>


                    {/* Reference Input Field */}
            <div className='mx-3 w-full'>
                <label className='block' htmlFor="reference">Reference:<span className='text-red-500'>*</span></label>
                <input
                    type="text"
                    className='border w-full border-gray-400 rounded-md p-2 mt-1 overflow-hidden shadow-md outline-none'
                    placeholder='Enter Reference'
                    value={Reference}
                    onChange={(e) => setReference(e.target.value)}
                    disabled={Source !== "Reference"} // Only enabled when Source is "Reference"
                />
            </div>
                   
                </div>

                <br />
                <div className='flex justify-start w-full'>
                    <div className='mx-4 w-1/3'>
                        <label className='block' htmlFor="Name">Group:<span className='text-red-500'>*</span></label>
                        <select  onClick={Ref} className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={AssignedGroup} onChange={(e) => setAssignedGroup(e.target.value)} name="auth" id="auth" required>
                            <option value="" >Select Group</option>
                            {GroupNames.map(group => (
                                <option key={group.id} value={group.newGroupName}>{group.newGroupName}</option>
                            ))}
                        </select>
                    </div>


                    <div className='mx-3 w-1/2'>
                        <label className='block' htmlFor="Name">Type of Service:<span className='text-red-500'>*</span></label>
                        <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={TypeService} onChange={(e) => setTypeService(e.target.value)} name="auth" id="auth">
                            <option value="" >Select Service Type</option>
                            {serviceTypes.map(serviceType => (
                                <option key={serviceType.id} value={serviceType.ServiceType}>{serviceType.ServiceType}</option>
                            ))}
                        </select>
                    </div>

                    {/* <div className='mx-4 w-1/3'>
                        <label className='block' htmlFor="Name">Client Contact Person Name:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Name' value={ClientContactName} onChange={(e) => setClientContactName(e.target.value)}/>
                    </div> */}


                    <div className='mx-3 w-1/2'>
                        <label className='block' htmlFor="Name">Project Name:<span className='text-red-500'>*</span></label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Project Name' value={ProjectName} onChange={(e) => setProjectName(e.target.value)} required/>
                    </div>


                    <div className='mx-3 w-1/2'>
                        <label className='block' htmlFor="Name">Quoted Value:<span className='text-red-500'>*</span></label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Quoted Value' inputMode="numeric" pattern="[0-9]*" value={formatNumberWithCommas(QuotedValue)} onChange={(e) => setQuotedValue(e.target.value.replace(/,/g, '').replace(/\D/g, ''))}/>
                    </div>
                    {/* <div className='mx-4 w-1/3'>
                        <label className='block' htmlFor="Name">Mobile Number:</label>
                        <PhoneInput country={'us'} value={ClientPhone} onChange={(value) => setClientPhone(value)} inputProps={{  required: true, className: 'w-11/12 border border-gray-400 outline-0 rounded overflow-hidden shadow-md ml-9 p-2', style: { fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif" }}}/>
                    </div>
                    <div className='mx-4 w-1/3'>
                        <label className='block' htmlFor="email">Email ID:</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Email ID' value={ClientEmail} onChange={(e) => setClientEmail(e.target.value)}/>
                    </div> */}
                </div>
                <br />

                <div className='flex justify-start w-full'>
                    <div className='mx-4 w-1/3'>
                        <label className='block' htmlFor="Name">Client Contact Person Name:<span className='text-red-500'>*</span></label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Name' value={ClientContactName} onChange={(e) => setClientContactName(e.target.value)} required/>
                    </div>

                    <div className='mx-4 w-1/3'>
                        <label className='block' htmlFor="email">Email ID:<span className='text-red-500'>*</span></label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="email" placeholder='Enter Email ID' value={ClientEmail} onChange={(e) => setClientEmail(e.target.value)} required/>
                    </div>
            
                    <div className='mx-4 w-1/3'>
                        <label className='block' htmlFor="Name">Mobile Number:<span className='text-red-500'>*</span></label>
                        <PhoneInput country={'us'} value={ClientPhone} onChange={(value) => setClientPhone(value)} inputProps={{  required: true, className: 'w-11/12 border border-gray-400 outline-0 rounded overflow-hidden shadow-md ml-9 p-2', style: { fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif" }}}/>
                    </div>

                    <div className='mx-3 w-1/3'>
                        <label className='block' htmlFor="Name">Lead Type:<span className='text-red-500'>*</span></label>
                        <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={LeadType} onChange={(e) => setLeadType(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Select Lead type</option>
                            {LeadTypes.map(LeadTyp => (
                                <option key={LeadTyp.id} value={LeadTyp.LeadType}>{LeadTyp.LeadType}</option>
                            ))}
                        </select>
                    </div>

                   {/* <div className='mx-3 w-1/3'>
                        <label className='block' htmlFor="auth">Assign Group(s):</label>
                        <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={AssignedGroup} onChange={(e) => setAssignedGroup(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Assign Group(s)</option>
                            {GroupNames.map(group => (
                                <option key={group.id} value={group.newGroupName}>{group.newGroupName}</option>
                            ))}
                        </select>
                    </div> */}

                </div>
                <br />
                <div className='flex justify-start w-full'>
                    {/* <div className='mx-3 w-full'>
                        <label className='block' htmlFor="Name">Lead Type:</label>
                        <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={LeadType} onChange={(e) => setLeadType(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Select Lead type</option>
                            {LeadTypes.map(LeadTyp => (
                                <option key={LeadTyp.id} value={LeadTyp.LeadType}>{LeadTyp.LeadType}</option>
                            ))}
                        </select>
                    </div> */}
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="Name">Status:<span className='text-red-500'>*</span></label>
                        <select onClick={Prob} className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={Status} onChange={(e) => setStatus(e.target.value)} name="auth" id="auth">
                            <option value="" disabled>Select Current Status</option>
                            {statuses.map(statuse => (
                                <option key={statuse.id} value={statuse.status}>{statuse.status}</option>
                            ))}
                        </select>
                    </div>
                  
                    <div className='mx-3 w-full'>
                        <label className='block' htmlFor="Name">Probability:<span className='text-red-500'>*</span></label>
                        <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={Probability} onChange={(e) => setProbability(e.target.value)}
                         name="auth" id="auth" disabled={Status !== "Pending"}>
                            <option value="" disabled>Select Probability</option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                        </select>
                    </div>
                    

                    <div className='mx-3 w-full'>
                    <label className='block' htmlFor="date">
                       {Status === "Won" || Status === "Lost" ? "No Follow-up Date Required": "Next Follow-up Date:"}
                     </label>
                     {Status !== "Won" && Status !== "Lost" && (
                      <DatePicker
                      selected={FollowupDate}
                      onChange={(date) => setFollowupDate(date)}
                      dateFormat="MM-dd-yyyy" // Customize the format as per your requirement
                      className="border w-full border-gray-400 rounded-md p-1.5 overflow-hidden shadow-md outline-none"
                      minDate={todaysDate} // Disable past dates
                      placeholderText="mm-dd-yyyy" // Placeholder
                      disabled={Status === "Won" || Status === "Lost"} // Disable the field based on status
                    />
                    )}
                    </div>

                </div>
                <br></br>
                <div className='mx-3 w-1/3'>
        <label className='block' htmlFor="comments">Comments:</label>
        <textarea 
            className='border w-[550px] border-gray-400 rounded-md p-4 overflow-hidden shadow-md outline-none resize-y ' 
            placeholder="Enter Comments" 
            id="comments" 
            value={comments}  
            onChange={(e) => setComments(e.target.value)}  
            required={Status === "Pending"} 
            name="comments"
            rows={2}  // Default height, you can change this as needed
              />
            </div>
                <br />
                <div className='flex  justify-left'>
                    <button onClick={Submit} className='mx-3 py-2 px-7 bg-black hover:bg-blue-900 overflow-hidden shadow-md text-white rounded-lg'>Submit</button>
                    <button onClick={Cancel} className='mx-3 py-2 px-7 bg-red-600 hover:bg-white hover:text-black border border-black overflow-hidden shadow-md text-white rounded-lg'>Cancel</button>

                </div>
                <br />
            </div>
            <br /><br /><br /><br /><br /><br />
        </>
    );
};

export default AddLead;
